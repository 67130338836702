




























@import '../utilities/config';
.row-login-info {
  min-height: 100vh;
  height: auto;
  padding-top: 100px;
  padding-bottom: 50px;
}

.row-login-form {
  min-height: 100vh;
  height: auto;
  background-color: gray('gray-100');
  padding-top: 100px;
  padding-bottom: 50px;
}
